.redCell{

    background-color: #CC0625;
    color: white;
}

.greenCell{

    background-color: #36B805;
    color: white;
}

.yellowCell{

    background-color:greenyellow;
    color: white;
}