@import "./scss/base";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


#content {
  min-height: 100vh;
  padding-top: 59px;
}

.rbc-month-view {
  overflow: hidden;
}

.with-background {
  background-color: $color-background;
  padding-top: 15px;
  padding-bottom: 15px;
}

table.responsive {
  display: block;
  overflow: auto;
  tbody {
    tr {
      th {
        position: sticky;
        left: 0px;
        z-index: 3;
        background-color: #eee;
      }
      &:nth-child(2n) {
        th {
          background-color: $color-background;
        }
      }
    }
  }
}

body .table-align-middle {
  .dx-datagrid-content .dx-datagrid-table .dx-row > td, .dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
    vertical-align: middle;
  }
}